import React, { useEffect, useState } from "react";
import Img from "../assets/images/Group 3.png";
import Faq from "../components/faq/faq";
import SEO from "../components/SEO";
import Spinner from "../components/preloader/Spinner";
import { useApi } from "../context/ApiContext";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const { getData, postData, loading, error } = useApi();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Make the POST request via context
      const response = await postData("userService/user/contactUs", formData);

      setIsLoading(false);
      toast.success(response.message);
      // Reset the form after submission
      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        message: "",
      });
    } catch (err) {
      console.error("Error submitting donation:", err);
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-col items-center">
      <SEO
        title="Contact"
        description="At BOAF, we are committed to helping underserved communities."
        name="BOAF community"
        type="description"
      />
      {/* Hero Section */}
      <div className="relative w-full">
        {/* Image */}
        <img
          src={Img}
          alt="Top Imge"
          className="w-full h-[200px] sm:h-[242px] object-cover z-0"
        />
        <h1 className="absolute top-[100px] sm:top-[150px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#00CED2] text-[34px] sm:text-[60px] font-inter font-semibold leading-[40px] sm:leading-[58px] tracking-[-0.03em] text-center z-10 lg:px-4">
          Contact Us
        </h1>
      </div>

      {/* Section: Empowering Communities */}
      <motion.div
          whileInView={{ scale: 1, transition: { duration: 0.1 } }}
          initial={{ scale: 0 }}
        >
      <div className="py-6 px-2 sm:px-6 md:px-12 max-w-screen-xl text-center">
        <h3 className="text-md sm:text-xl font-semibold text-[#212121] mt-4 mb-4">
          Empowering Communities | Transforming Lives
        </h3>
        <p className="font-normal text-xs sm:text-sm  text-center font-inter text-[#2D2D2D]  sm:max-w-[764px] mx-auto">
          At BOAF, we believe that every woman, child, and community leader has
          the potential to create a brighter future. <br />
          Our mission is to empower women and nurture community leaders through
          impactful initiatives that address critical social issues, improve
          living conditions, and foster sustainable development.
        </p>
      </div>
      </motion.div>

      {/* Contact Form and Floating Info Box Section */}
      <motion.div
          whileInView={{ scale: 1, transition: { duration: 0.1 } }}
          initial={{ scale: 0 }}

        >
      <div className="bg-[#E4E4E4] rounded-[20px] w-full px-6 sm:px-10 py-8 sm:py-12 flex flex-col">
        <h2 className="font-inter font-semibold text-[18px] sm:text-[20px] leading-[28px] text-[#252A34] mb-4">
          Get In Touch
        </h2>
        <h1 className="text-[#252A34] font-inter font-bold text-[28px] sm:text-[39px] leading-[36px] sm:leading-[50px] mb-6">
          Send us a message
        </h1>

        <div className="flex flex-col lg:flex-row justify-between items-start gap-10">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full lg:w-[60%]"
          >
            {/* First Name */}
            <div className="flex flex-col">
              <label
                htmlFor="first-name"
                className="font-inter font-medium text-[16px] leading-[24px] text-[#252A34] mb-2"
              >
                First Name
              </label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                required
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Your Name"
                className="px-4 py-3 border border-[#D9D7D7]  rounded-[4px]"
              />
            </div>

            {/* Last Name */}
            <div className="flex flex-col">
              <label
                htmlFor="last-name"
                className="font-inter font-medium text-[16px] leading-[24px] text-[#252A34] mb-2"
              >
                Last Name
              </label>
              <input
                type="text"
                id="last-name"
                name="lastName"
                required
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Your Name"
                className="px-4 py-3 border border-[#D9D7D7]  rounded-[4px]"
              />
            </div>

            {/* Email Address */}
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="font-inter font-medium text-[16px] leading-[24px] text-[#252A34] mb-2"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                className="px-4 py-3 border border-[#D9D7D7]  rounded-[4px]"
              />
            </div>

            {/* Phone Number */}
            <div className="flex flex-col">
              <label
                htmlFor="phone"
                className="font-inter font-medium text-[16px] leading-[24px] text-[#252A34] mb-2"
              >
                Phone Number
              </label>
              <input
                type="text"
                id="phone"
                name="phoneNumber"
                required
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="+234"
                className="px-4 py-3 border border-[#D9D7D7]  rounded-[4px]"
              />
            </div>

            {/* Message */}
            <div className="flex flex-col sm:col-span-2">
              <label
                htmlFor="message"
                className="font-inter font-medium text-[16px] leading-[24px] text-[#252A34] mb-2"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Your Message"
                required
                value={formData.message}
                onChange={handleChange}
                rows="6"
                className="bg-[#EDF7F5] px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2] focus:border-[#00CED2]"
              />
            </div>

            {/* Submit Button */}
            <div className="col-span-1 sm:col-span-2">
              <button
                type="submit"
                className="bg-[#00CED2] text-[#2D2D2D] w-full sm:w-[225px] h-[42px] rounded-[8px] px-6 py-2 font-inter font-bold text-[16px] leading-[30px] text-center"
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  <span className="flex items-center justify-center gap-x-1">
                    Send us your message
                  </span>
                )}
              </button>
            </div>
          </form>

          {/* Floating Info Box */}
          <div className="bg-white w-full lg:w-[443px] rounded-tl-[4px] rounded-tr-[20px] rounded-br-[4px] rounded-bl-[20px] shadow-lg p-6 sm:p-10 space-y-4">
            <h1 className="text-xl sm:text-[39px] mb-3 text-[#252A34] font-medium">
              Reach out to us
            </h1>
            <p className="text-sm sm:text-[14px] text-[#666666] font-inter font-normal">
              Ready to take the next step? Reach out to us for more information
              on how you can help or get involved. Together, we can build
              opportunities and futures for everyone.
            </p>
            <div>
              <p>Location: Lagos, Nigeria</p>
              <p>Phone: +2349165427266</p>
              <p className="text-[#000000] font-inter font-semibold text-[16px]">
                Email: support@boafcommunity.com
              </p>
            </div>
          </div>
        </div>
      </div>
      </motion.div>

      {/* Frequently Asked Questions (FAQ) */}
      <div className="w-full mt-12">
      <motion.div
          whileInView={{ scale: 1, transition: { duration: 0.1 } }}
          initial={{ scale: 0 }}
        >
        <Faq />
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
