import React, { useEffect, useState } from "react";
import { useApi } from "../../context/ApiContext";
import { toast } from "react-toastify";
import Spinner from "../preloader/Spinner";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import success from "../../assets/icons/success.gif";

function BankSetting() {
  const [modal, setModal] = useState(false);
  const [responseText, setResponseText] = useState("");
  const { getData, postData } = useApi();
  const { userInfo } = useSelector((state) => state.auth);
  const [accountName, setAccountName] = useState("");
  const [bank, setBank] = useState("Select Bank");
  const [bankList, setBankList] = useState([]);
  const [details, setDetails] = useState([]);
  const [accountNumber, setAccountNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Prepare payload
      const payload = {
        accountName,
        accountNumber,
        bankCode: bank.bankCode,
        bankName: bank.bankName,
        email: userInfo.data.email,
      };

      // Make the POST request via context
      const response = await postData(
        "/userService/user/dashboard/updateBankAccountDetails",
        payload
      );

      setIsLoading(false);
      setResponseText(response?.data?.message || response.message);
      setModal(true);

      fetchDetails();
    } catch (err) {
      toast.error(err.response?.data?.message || err.response?.message);
      setIsLoading(false);
    }
  };

  const fetchDetails = async () => {
    const data = { email: userInfo.data.email };

    try {
      // Make the POST request via context
      const response = await postData(
        "/userService/user/dashboard/getUserInfo",
        data
      );

      setDetails(response.data.withdraw_info);
    } catch (error) {
      console.error("Error fetching Details:", error);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // Fetch Account Details
    fetchDetails();

    const fetchBanks = async () => {
      try {
        // Make the POST request via context
        const response = await getData(
          "/paymentService/withdrawal/listOfBanks"
        );

        setBankList(response.data);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };
    fetchBanks();
  }, []);

  const fetchAccount = async () => {
    const data = {
      accountNumber,
      bankCode: bank.bankCode,
      bankName: bank.bankName,
    };

    try {
      // Make the POST request via context
      const response = await postData(
        "/paymentService/withdrawal/getBankAccountDetails",
        data
      );

      setAccountName(response.data.account_name);
    } catch (error) {
      console.error("Error fetching banks:", error);
      toast.error("Incorrect account details");
      setAccountName("");
    }
  };

  useEffect(() => {
    if (accountNumber.length > 9) {
      fetchAccount();
    }
  }, [accountNumber]);
  return (
    <>
      {details.bank_name === "" ? (
        <form
          onSubmit={handleSubmit}
          className="space-y-4 px-4 pb-10  transition-all transform bg-white rounded-2xl shadow-3xl w-full sm:max-w-sm sm:px-6 md:px-8  "
        >
          <hr className="h-2 rounded-full bg-[#00CED2] w-[9rem] mx-auto mt-1" />
          <p className="text-sm font-semibold text-[#1C2E62] text-center">
            Updating bank details can only be done once, Please fill in your
            details correctly.
          </p>

          <div className="w-full pt-3 lg:pt-0">
            <label className="text-sm block text-left font-semibold">
              Bank
            </label>

            <div className="relative">
              <select
                onChange={(e) => {
                  const { bankName, bankCode } = JSON.parse(e.target.value);
                  setBank({ bankName, bankCode });
                }}
                className="mt-1 block w-full p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:ring-primary-blue focus:border-primary-blue appearance-none"
              >
                <option value="">{bank.bankName || "Select a bank"}</option>
                {bankList.map((item) => (
                  <option
                    key={item.code}
                    value={JSON.stringify({
                      bankName: item.name,
                      bankCode: item.code,
                    })}
                  >
                    {item.name}
                  </option>
                ))}
              </select>

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="mb-3 mt-3">
            <label className="text-sm block text-left text-gray-900 font-semibold">
              Account Number
            </label>
            <input
              type="text"
              value={accountNumber}
              onChange={(e) => {
                setAccountNumber(e.target.value);
              }}
              max={10}
              placeholder="Your account number"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-primary-blue focus:border-primary-blue"
            />
          </div>
          <div className="mb-3">
            <label className="text-sm block text-left text-gray-900 font-semibold">
              Account Name
            </label>
            <div className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-primary-blue focus:border-primary-blue">
              {accountName}
            </div>
          </div>

          <button
            className="w-full py-2 bg-[#00CED2] text-[#fff] rounded-[15px] font-semibold"
            type="submit"
            disabled={isLoading || accountName === ""}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <span className="flex items-center justify-center gap-x-1">
                Update Details
              </span>
            )}
          </button>

          <hr className="w-full" />
        </form>
      ) : (
        <div className="space-y-4 px-4 pb-10  transition-all transform bg-white rounded-2xl shadow-3xl w-full sm:w-[23rem] sm:px-6 md:px-8  ">
          <hr className="h-2 rounded-full bg-[#00CED2] w-[9rem] mx-auto mt-1" />
          <p className="text-sm font-semibold text-[#1C2E62] text-center">
            To change your bank information, please reach out to{" "}
            <Link to="/contact" className="underline underline-offset-4">
              support team.
            </Link>
          </p>
          <div>
            <label htmlFor="" className="text-left py-3 font-semibold">
              Bank
            </label>
            <h1 className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]">
              {details.bank_name}
            </h1>
          </div>
          <div>
            <label htmlFor="" className="text-left py-3 font-semibold">
              Account Number
            </label>
            <h1 className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]">
              {details.bank_account_number}
            </h1>
          </div>
          <div>
            <label htmlFor="" className="text-left py-3 font-semibold">
              Account Name
            </label>
            <h1 className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]">
              {details.account_name}
            </h1>
          </div>
        </div>
      )}

      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:px-6  sm:align-middle ">
              <hr className="h-2 rounded-full bg-[#00CED2] w-[7rem] lg:w-[15rem] mx-auto mt-1" />
              <div className="md:p-8">
                <div className="flex flex-col space-y-3  mx-auto my-2">
                  <img
                    src={success}
                    alt="Thank You Animation"
                    className="mx-auto mb-4"
                  />
                  <h2 className="font-semibold bg-[#00CED2]/50 p-4 rounded-xl shadow-xl">
                    {responseText}
                  </h2>

                  <button
                    onClick={() => setModal(false)}
                    className="w-[8rem] mx-auto mt-6 px-6 py-2 bg-[#CCCCCC] font-semibold rounded-md shadow-xl"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BankSetting;
