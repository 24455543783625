import React, { useEffect } from "react";
import Initiatives from "../components/initiatives/initiatives";
import SEO from "../components/SEO";
import Img from "../assets/images/Group 3.png";
const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <SEO
        title="About"
        description="At BOAF, we are committed to helping underserved communities."
        name="BOAF community"
        type="description"
      />
       {/* Hero Section */}
            <div className="relative w-full">
              {/* Image */}
              <img
                src={Img}
                alt="Top Imge"
                className="w-full h-[200px] sm:h-[242px] object-cover z-0"
              />
              <h1 className="absolute top-[100px] sm:top-[150px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#00CED2] text-[34px] sm:text-[60px] font-inter font-semibold leading-[40px] sm:leading-[58px] tracking-[-0.03em] text-center z-10 lg:px-4">
                About Us
              </h1>
            </div>
      <Initiatives />
    </div>
  );
};

export default About;
