import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { setCredentials } from "../../Slices/authSlice";
import { useLoginMutation } from "../../Slices/userServiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SEO from "../../components/SEO";
import Spinner from "../../components/preloader/Spinner";
import Img from "../../assets/images/Group 3.png";

function Login() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordValue, setPasswordValue] = useState("");
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);
  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    const cursorPosition = e.target.selectionStart;
    const textBeforeCursor = passwordValue.substring(0, cursorPosition);
    const textAfterCursor = passwordValue.substring(cursorPosition);
    setPasswordValue(textBeforeCursor + text.trim() + textAfterCursor);
  };

  const loginHandler = async (data) => {
    try {
      const res = await login(data).unwrap();
      dispatch(setCredentials({ ...res }));

      navigate("dashboard/index");
    } catch (err) {
      toast.error(err?.data?.message || err.data.error);
    }
  };

  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/dashboard/index";

  useEffect(() => {
    if (userInfo !== null) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  return (
    <>
      <SEO
        title="Login"
        description="At BOAF, we are committed to helping underserved communities."
        name="BOAF community"
        type="description"
      />
      {/* Hero Section */}
      <div className="relative w-full">
        {/* Image */}
        <img
          src={Img}
          alt="Top Imge"
          className="w-full h-[200px] sm:h-[242px] object-cover z-0"
        />
        <h1 className="absolute top-[100px] sm:top-[150px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#00CED2] text-[34px] sm:text-[60px] font-inter font-semibold leading-[40px] sm:leading-[58px] tracking-[-0.03em] text-center z-10 lg:px-4">
          Login
        </h1>
      </div>
      <div className="px-3 lg:px-24 py-6 lg:py-10 lg:w-[40rem] mx-auto">
        <div className="mx-auto">
          <form
            className="bg-[#fff] lg:p-10 rounded-2xl"
            onSubmit={handleSubmit(loginHandler)}
          >
            <h3
              className="text-2xl text-center font-bold leading-6 text-gray-800 capitalize "
              id="modal-title"
            >
              Partners Login
            </h3>
            <div className="flex my-5">
              <span className="relative -mr-12  flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.1816 3.5H6.68164C4.4725 3.5 2.68164 5.29086 2.68164 7.5V16.5C2.68164 18.7091 4.4725 20.5 6.68164 20.5H17.1816C19.3908 20.5 21.1816 18.7091 21.1816 16.5V7.5C21.1816 5.29086 19.3908 3.5 17.1816 3.5Z"
                    stroke="#00CED2"
                    stroke-width="1.5"
                  />
                  <path
                    d="M2.72852 7.59L9.93352 11.72C10.5373 12.0704 11.223 12.2549 11.921 12.2549C12.6191 12.2549 13.3047 12.0704 13.9085 11.72L21.1335 7.59"
                    stroke="#00CED2"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <input
                type="email"
                require
                placeholder="Email Address"
                {...register("email")}
                className="py-3 px-10 border-[0.5px] bg-[#FFF] border-[#94A5AB] rounded-lg w-full focus:outline-none placeholder:text-[#94A3B8] placeholder:font-light"
              />
            </div>

            <div className="mb-5">
              <div className="flex -mr-px">
                <span className="relative -mr-12  flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.75 1.5C14.6959 1.49976 13.6565 1.74642 12.7149 2.22023C11.7734 2.69404 10.9559 3.38181 10.328 4.22844C9.7001 5.07506 9.27925 6.057 9.09917 7.09555C8.91909 8.13411 8.98479 9.20041 9.291 10.209L1.5 18V22.5H6L13.791 14.709C14.7194 14.9908 15.6977 15.0692 16.6591 14.9387C17.6206 14.8083 18.5426 14.4721 19.3624 13.953C20.1821 13.434 20.8804 12.7444 21.4095 11.9311C21.9386 11.1178 22.2862 10.2 22.4285 9.24025C22.5709 8.28049 22.5046 7.30132 22.2343 6.36948C21.964 5.43764 21.496 4.57502 20.8622 3.84042C20.2283 3.10582 19.4436 2.51649 18.5614 2.11261C17.6792 1.70872 16.7203 1.49978 15.75 1.5ZM15.75 13.5C15.2336 13.4999 14.7201 13.4235 14.226 13.2735L13.3657 13.0125L12.7305 13.6478L10.3448 16.0335L9.3105 15L8.25 16.0605L9.28425 17.0947L8.09475 18.2843L7.0605 17.25L6 18.3105L7.03425 19.3447L5.379 21H3V18.621L10.3515 11.2695L10.9875 10.6342L10.7265 9.774C10.4059 8.71724 10.4268 7.58631 10.786 6.54207C11.1453 5.49784 11.8247 4.59347 12.7275 3.95762C13.6304 3.32177 14.7108 2.98681 15.815 3.0004C16.9192 3.01398 17.9911 3.37542 18.878 4.03329C19.765 4.69116 20.4219 5.61197 20.7554 6.66473C21.0888 7.71749 21.0818 8.84859 20.7354 9.89714C20.3889 10.9457 19.7206 11.8583 18.8256 12.5051C17.9305 13.152 16.8543 13.5001 15.75 13.5Z"
                      fill="#00CED2"
                    />
                    <path
                      d="M16.5 9C17.3284 9 18 8.32843 18 7.5C18 6.67157 17.3284 6 16.5 6C15.6716 6 15 6.67157 15 7.5C15 8.32843 15.6716 9 16.5 9Z"
                      fill="#00CED2"
                    />
                  </svg>
                </span>
                <input
                  required
                  {...register("password", { required: true, trim: true })}
                  value={passwordValue}
                  type={passwordType}
                  placeholder="Password"
                  className="py-3 px-10 border-[0.5px] bg-[#FFF] border-[#94A5AB] rounded-lg w-full focus:outline-none placeholder:text-[#94A3B8] placeholder:font-light"
                  onChange={(e) => setPasswordValue(e.target.value)}
                  onPaste={handlePaste}
                />
                <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                  <button
                    type="button"
                    onClick={() => {
                      if (passwordType === "password") {
                        setPasswordType("text");
                        return;
                      }
                      setPasswordType("password");
                    }}
                    className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                  >
                    {passwordType === "password" ? (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.2556 2.74408C17.5811 3.06951 17.5811 3.59715 17.2556 3.92259L3.9223 17.2559C3.59687 17.5814 3.06923 17.5814 2.74379 17.2559C2.41836 16.9305 2.41836 16.4028 2.74379 16.0774L16.0771 2.74408C16.4026 2.41864 16.9302 2.41864 17.2556 2.74408Z"
                          fill="black"
                        />
                        <path
                          d="M13.166 4.4767C12.2274 4.03469 11.1691 3.75 9.9997 3.75C7.54629 3.75 5.58202 5.00308 4.18604 6.33307C2.78685 7.66611 1.87897 9.14973 1.51958 9.7915C1.28406 10.2121 1.25601 10.7143 1.44934 11.1603C1.58435 11.4718 1.81395 11.9544 2.15237 12.5143C2.39041 12.9082 2.9027 13.0346 3.29659 12.7965C3.69049 12.5585 3.81683 12.0462 3.57879 11.6523C3.3066 11.2019 3.11772 10.8124 3.00316 10.5537C3.34129 9.95778 4.14208 8.67693 5.33569 7.53975C6.56858 6.36513 8.14141 5.41667 9.9997 5.41667C10.6681 5.41667 11.2995 5.53937 11.8915 5.75116L13.166 4.4767Z"
                          fill="black"
                        />
                        <path
                          d="M14.7418 7.61491C15.8923 8.73288 16.6655 9.97087 16.9962 10.5537C16.8817 10.8124 16.6928 11.2019 16.4206 11.6523C16.1826 12.0462 16.3089 12.5585 16.7028 12.7965C17.0967 13.0346 17.609 12.9082 17.847 12.5143C18.1855 11.9544 18.4151 11.4718 18.5501 11.1603C18.7434 10.7143 18.7153 10.2121 18.4798 9.7915C18.1297 9.16625 17.2589 7.74193 15.9204 6.43629L14.7418 7.61491Z"
                          fill="black"
                        />
                        <path
                          d="M9.99971 6.66667C10.3026 6.66667 10.5979 6.69898 10.8824 6.76034L9.1675 8.47519C8.45626 8.7262 7.89258 9.28987 7.64157 10.0011L5.92672 11.716C5.86536 11.4315 5.83305 11.1362 5.83305 10.8333C5.83305 8.53215 7.69853 6.66667 9.99971 6.66667Z"
                          fill="black"
                        />
                        <path
                          d="M9.99971 13.3333C9.70732 13.3333 9.42665 13.2831 9.16585 13.1909L7.91498 14.4418C8.52815 14.7968 9.2402 15 9.99971 15C12.3009 15 14.1664 13.1345 14.1664 10.8333C14.1664 10.0738 13.9632 9.36177 13.6081 8.74859L12.3573 9.99947C12.4495 10.2603 12.4997 10.5409 12.4997 10.8333C12.4997 12.214 11.3804 13.3333 9.99971 13.3333Z"
                          fill="black"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </button>
                </span>
              </div>
            </div>

            <div className="flex flex-col space-y-5 w-full">
              <button
                type="submit"
                value="submit"
                className="w-full bg-[#00CED2] rounded-lg p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  <span className="flex items-center justify-center gap-x-1">
                    Login
                  </span>
                )}
              </button>
            </div>

            <div className="my-5 flex gap-x-1 justify-start items-center">
                <p className="text-sm text-[#1a1a1a] font-light ">
                  Forgot password?
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/forgotpassword");
                  }}
                  className="text-xs font-semibold text-[#00CED2]"
                >
                  Reset Password
                </button>
              </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
