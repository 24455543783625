import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SEO from "../../components/SEO";
import Spinner from "../../components/preloader/Spinner";
import Img from "../../assets/images/Group 3.png";
import { useParams } from "react-router-dom";
import { useNewPasswordMutation } from "../../Slices/userServiceSlice";

function NewPassword() {
  const [modal, setModal] = useState(false);
  const params = useParams();
  const { email } = params;
  const { register, handleSubmit, setValue } = useForm();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setValue("email", email);
  }, []);

  const navigate = useNavigate();

  const [newPassword, { isLoading }] = useNewPasswordMutation();

  const createHandler = async (data) => {
    try {
      const res = await newPassword(data);
      toast.success(res?.data?.msg);
      if (res.data?.status === 200) {
        setModal(true);
      } else {
        toast.error(res?.error.data?.msg);
      }
    } catch (err) {
      toast.error(err?.data?.msg || err.error);
    }
  };

  return (
    <>
      <SEO
        title="New Password"
        description="At BOAF, we are committed to helping underserved communities."
        name="BOAF community"
        type="description"
      />

      {/* Hero Section */}
      <div className="relative w-full">
        {/* Image */}
        <img
          src={Img}
          alt="Top Imge"
          className="w-full h-[200px] sm:h-[242px] object-cover z-0"
        />
        <h1 className="absolute top-[100px] sm:top-[150px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#00CED2] text-[34px] sm:text-[60px] font-inter font-semibold leading-[40px] sm:leading-[58px] tracking-[-0.03em] text-center z-10 lg:px-4">
        New Password
        </h1>
      </div>
      <div className="px-3 lg:px-24 py-6 lg:py-10 lg:w-[40rem] mx-auto">
        <div className="mx-auto">
          <div className="hidden lg:block">
            <h3
              className="text-2xl lg:text-4xl  font-bold leading-6 text-gray-800 capitalize "
              id="modal-title"
            >
            Create New Password
            </h3>
            <p className="mt-2 text-sm text-[#495454] font-light ">
              Enter a New Password.
            </p>
          </div>

          <form
            className="mt-4  "
            onSubmit={handleSubmit(createHandler)}
          >
            <div className="flex mb-5">
              <span className="relative -mr-12  flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                    stroke="#64748B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3 7L12 13L21 7"
                    stroke="#64748B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <input
                type="email"
                require
                {...register("email")}
                className="hidden py-3 px-10 border-[0.5px] bg-[#FFF] border-[#94A5AB] rounded-lg w-full focus:outline-none placeholder:text-[#94A3B8] placeholder:font-light"
              />
              <input
                type="text"
                require
                {...register("newPassword")}
                className="py-3 px-10 border-[0.5px] bg-[#FFF] border-[#94A5AB] rounded-lg w-full focus:outline-none placeholder:text-[#94A3B8] placeholder:font-light"
              />
            </div>

            <div className="flex flex-col space-y-5 w-full">
              <button
                type="submit"
                className="w-full bg-[#00CED2] rounded-lg p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  <span className="flex items-center justify-center gap-x-1">
                    Create
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen px-4 pt-12 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle">
              <div className="py-4 space-y-5 justify-center">
                
                <h3
                  className="text-2xl lg:text-4xl  font-bold leading-6 text-gray-800 capitalize "
                  id="modal-title"
                >
                  Reset successful
                </h3>
                <p className="mt-2 text-sm text-[#495454] font-light ">
                  Your password has been successfully updated.
                </p>

                <div className="flex flex-col space-y-5 w-full">
                  <button
                    onClick={() => {
                      setModal(false);
                      navigate("/login");
                    }}
                    className="w-full bg-[#00CED2] rounded-lg p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                  >
                   Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
}

export default NewPassword;
