import React, { useState } from "react";
import { formatNumber } from "../../Slices/utilSlice";
import success from "../../assets/icons/success.gif";
import failed from "../../assets/icons/failed.gif";

function DashboardCards({ data, userInfo }) {
  const [tab, setTab] = useState(1);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);

  return (
    <div>
      <div className="px-5 pt-5 flex items-center  sm:w-[22rem] ">
        <button
          className={`w-full py-1 px-5 text-[#252A34B2] rounded-l-lg font-inter font-semibold text-base leading-7 text-center ${
            tab === 1
              ? "bg-[#07215d] text-white"
              : "hover:bg-[#07215d] hover:text-white bg-[#FFF]"
          }`}
          onClick={() => setTab(1)}
        >
          Current
        </button>
        <button
          className={`w-full py-1 text-[#252A34B2]  rounded-r-lg font-inter font-semibold text-base leading-7 text-center ${
            tab === 2
              ? "bg-[#07215d] text-white"
              : "hover:bg-[#07215d] hover:text-white bg-[#FFF]"
          }`}
          onClick={() => setTab(2)}
        >
          Total Activity
        </button>
      </div>
      {tab === 1 && (
        <div className="p-5 grid grid-cols-1 lg:grid-cols-3 gap-5">
       

          <div className="bg-[#0F45C3]/20 p-4 rounded-lg lg:space-y-3">
            <p className="text-xs text-[#0F45C3]">Your Total Support</p>
            <h1 className="font-semibold text-3xl text-[#2D2D2D]/80">
              ₦ {formatNumber(data.totalDonated)}
            </h1>
          </div>
          <div className="bg-[#0F45C3]/20 p-4 rounded-lg lg:space-y-3">
            <p className="text-xs text-[#0F45C3]">Total Number of Referrals</p>
            <h1 className="font-semibold text-3xl text-[#2D2D2D]/80">
              {data.totalReferrals}
            </h1>
          </div>
          <div className="bg-[#0F45C3]/20 p-4 rounded-lg lg:space-y-3">
            <p className="text-xs text-[#0F45C3]">
              Reward Accrued From Referral
            </p>

            <div className="flex justify-between">
              {data.walletBalance !== undefined && (
                <h1 className="font-semibold text-3xl text-[#2D2D2D]/80">
                  ₦ {data.walletBalance.toLocaleString()}
                </h1>
              )}
              <button
                onClick={() => {
                  if (userInfo.withdraw_info.account_name === "") {
                    setModal3(true);
                  } else {
                    if (data.walletBalance <= 0) {
                      setModal2(true);
                    } else {
                      setModal(true);
                    }
                  }
                }}
                className="px-3 rounded-full bg-[#07215D] text-[#fff] font-semibold text-sm leading-[30px] text-center"
              >
                Withdrawal
              </button>
            </div>
          </div>
        </div>
      )}
      {tab === 2 && (
        <div className="p-5 grid grid-cols-1 lg:grid-cols-3 gap-5">
          <div className="bg-[#0F45C3]/20 p-4 rounded-lg lg:space-y-3">
            <p className="text-xs text-[#0F45C3]">Your Total Support</p>
            <h1 className="font-semibold text-3xl text-[#2D2D2D]/80">
              ₦ {formatNumber(data.historicalStat.totalDonated)}
            </h1>
          </div>
          <div className="bg-[#0F45C3]/20 p-4 rounded-lg lg:space-y-3">
            <p className="text-xs text-[#0F45C3]">Total Number of Referrals</p>
            <h1 className="font-semibold text-3xl text-[#2D2D2D]/80">
              {data.historicalStat.totalReferrals}
            </h1>
          </div>

       
          <div className="bg-[#0F45C3]/20 p-4 rounded-lg lg:space-y-3">
            <p className="text-xs text-[#0F45C3]">
           Total Reward Accrued From Referral
            </p>

            <div className="flex justify-between">
              {data.historicalStat.walletBalance !== undefined && (
                <h1 className="font-semibold text-3xl text-[#2D2D2D]/80">
                  ₦ {data.historicalStat.walletBalance.toLocaleString()}
                </h1>
              )}
              <button
                onClick={() => {
                  if (userInfo.withdraw_info.account_name === "") {
                    setModal3(true);
                  } else {
                    if (data.walletBalance <= 0) {
                      setModal2(true);
                    } else {
                      setModal(true);
                    }
                  }
                }}
                className="px-3 rounded-full bg-[#07215D] text-[#fff] font-semibold text-sm leading-[30px] text-center"
              >
                Withdrawal
              </button>
            </div>
          </div>
        </div>
      )}

      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:px-6  sm:align-middle ">
              <hr className="h-2 rounded-full bg-[#00CED2] w-[7rem] lg:w-[15rem] mx-auto mt-1" />
              <div className="md:p-8">
                <div className="flex flex-col space-y-3  mx-auto my-2">
                  <img
                    src={success}
                    alt="Thank You Animation"
                    className="mx-auto mb-4"
                  />
                  <h2 className="font-semibold bg-[#00CED2]/50 p-4 rounded-xl shadow-xl">
                    Withdrawal Approved! <br /> Rewards will be credited to your
                    account within 24 hours.
                  </h2>

                  <button
                    onClick={() => setModal(false)}
                    className="w-[8rem] mx-auto mt-6 px-6 py-2 bg-[#CCCCCC] font-semibold rounded-md shadow-xl"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {modal2 && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:px-6  sm:align-middle ">
              <hr className="h-2 rounded-full bg-[#00CED2] w-[7rem] lg:w-[15rem] mx-auto mt-1" />
              <div className="md:p-8">
                <div className="flex flex-col space-y-3  mx-auto my-2">
                  <img
                    src={failed}
                    alt="Thank You Animation"
                    className="mx-auto mb-4"
                  />
                  <h2 className="font-semibold bg-[#00CED2]/50 p-4 rounded-xl shadow-xl">
                    You are currently not eligible.
                  </h2>

                  <button
                    onClick={() => setModal2(false)}
                    className="w-[8rem] mx-auto mt-6 px-6 py-2 bg-[#CCCCCC] font-semibold rounded-md shadow-xl"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {modal3 && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:px-6  sm:align-middle ">
              <hr className="h-2 rounded-full bg-[#00CED2] w-[7rem] lg:w-[15rem] mx-auto mt-1" />
              <div className="md:p-8">
                <div className="flex flex-col space-y-3  mx-auto my-2">
                  <img
                    src={failed}
                    alt="Thank You Animation"
                    className="mx-auto mb-4"
                  />
                  <h2 className="font-semibold  bg-[#FEF6E7] p-4 rounded-xl shadow-xl">
                    Update Your Bank Details
                  </h2>

                  <button
                    onClick={() => setModal3(false)}
                    className="w-[8rem] mx-auto mt-6 px-6 py-2 bg-[#CCCCCC] font-semibold rounded-md shadow-xl"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardCards;
