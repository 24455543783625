import React, { useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../preloader/Spinner";
import { toast } from "react-toastify";
import { useApi } from "../../context/ApiContext";
import success from "../../assets/icons/success.gif";

function ChangePassword() {
  const [modal, setModal] = useState(false);
  const [responseText, setResponseText] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const { postData } = useApi();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Prepare payload
      const payload = {
        email: userInfo.data.email,
        currentPassword,
        newPassword,
      };

      // Make the POST request via context
      const response = await postData(
        "/userService/user/dashboard/changePasssword",
        payload
      );

      setIsLoading(false);
      setResponseText(response?.data?.message || response.message);
      setModal(true);
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="space-y-4 px-4 pb-10  transition-all transform bg-white rounded-2xl shadow-3xl w-full sm:max-w-md sm:px-6 md:px-8  "
      >
        <hr className="h-2 rounded-full bg-[#00CED2] w-[9rem] mx-auto my-1" />

        <div>
          <label htmlFor="" className="text-left py-2 font-semibold">
            Current Password
          </label>
          <input
            type="text"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            className="w-full  px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
          />
        </div>
        <div>
          <label htmlFor="" className="text-left py-2 font-semibold">
            New Password
          </label>
          <input
            type="text"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="w-full  px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
          />
        </div>
        <button
          className="w-full py-2 bg-[#00CED2] text-[#fff] rounded-[9px] font-semibold"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <span className="flex items-center justify-center gap-x-1">
              Change Password
            </span>
          )}
        </button>

        <hr className="w-full" />
      </form>
      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:px-6  sm:align-middle ">
              <hr className="h-2 rounded-full bg-[#00CED2] w-[7rem] lg:w-[15rem] mx-auto mt-1" />
              <div className="md:p-8">
                <div className="flex flex-col space-y-3  mx-auto my-2">
                  <img
                    src={success}
                    alt="Thank You Animation"
                    className="mx-auto mb-4"
                  />
                  <h2 className="font-semibold bg-[#00CED2]/50 p-4 rounded-xl shadow-xl">
                    {responseText}
                  </h2>

                  <button
                    onClick={() => setModal(false)}
                    className="w-[8rem] mx-auto mt-6 px-6 py-2 bg-[#CCCCCC] font-semibold rounded-md shadow-xl"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChangePassword;
