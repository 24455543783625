import React, { useEffect, useState } from "react";
import SEO from "../components/SEO";
import Img from "../assets/images/Group 3.png";
import { motion, AnimatePresence } from "framer-motion";
import { useGetAllFoldersQuery } from "../Slices/userServiceSlice";
import PageLoader from "../components/preloader/PageLoader";
import ErrorLoading from "../components/preloader/ErrorLoading";
import { useApi } from "../context/ApiContext";
import { X, ArrowLeft, ArrowRight } from "lucide-react";

const Gallery = () => {
  const [selectedFolder, setSelectedFolder] = useState("");
  const { data, isLoading, isError } = useGetAllFoldersQuery();
  const [folders, setFolders] = useState([]);
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const { getData } = useApi();
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (data) {
      setFolders(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (folders && folders.length > 0 && !selectedFolder) {
      setSelectedFolder(folders[0].name);
      const fetchImages = async () => {
        setImageLoading(true);

        const url = `userService/user/gallery/getImages/${folders[0].name}`;

        try {
          const response = await getData(url, {});
          setImages(response.data);
          setImageLoading(false);
        } catch (err) {
          setError(err.message);
        } finally {
          setImageLoading(false);
        }
      };
      fetchImages();
    }
  }, [folders]);

  // Fetch Images per Folder
  const fetchImages = async (data) => {
    setImageLoading(true);

    const url = `userService/user/gallery/getImages/${data}`;

    try {
      const response = await getData(url, {});
      setImages(response.data);
      setImageLoading(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setImageLoading(false);
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleNext = () => {
    setSelectedIndex((prev) => (prev + 1) % images.length);
  };

  const handlePrev = () => {
    setSelectedIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  // Close on ESC key press
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") setSelectedIndex(null);
      if (e.key === "ArrowRight") handleNext();
      if (e.key === "ArrowLeft") handlePrev();
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);
  return (
    <div>
      <SEO
        title="Gallery"
        description="At BOAF, we are committed to helping underserved communities."
        name="BOAF community"
        type="description"
      />
      {/* Hero Section */}
      <div className="relative w-full">
        {/* Image */}
        <img
          src={Img}
          alt="Top Imge"
          className="w-full h-[200px] sm:h-[242px] object-cover z-0"
        />
        <h1 className="absolute top-[100px] sm:top-[150px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#00CED2] text-[34px] sm:text-[60px] font-inter font-semibold leading-[40px] sm:leading-[58px] tracking-[-0.03em] text-center z-10 lg:px-4">
          BOAF Gallery
        </h1>
      </div>

      <motion.div
        whileInView={{ scale: 1, transition: { duration: 0.1 } }}
        initial={{ scale: 0 }}
      >
        <div className="py-6 px-4 sm:px-6 md:px-12 max-w-screen-xl text-center">
          <p className="font-medium text-sm text-center font-inter text-[#2D2D2D]  sm:max-w-[764px] mx-auto">
            At BOAF, we believe that every woman, child, and community leader
            has the potential to create a brighter future. <br />
            Our mission is to empower women and nurture community leaders
            through impactful initiatives that address critical social issues,
            improve living conditions, and foster sustainable development.
          </p>
        </div>
      </motion.div>

      {/* Tabs +++++++++++++++++++++++++++++++++++++++ */}
      {isLoading ? (
        <div className="pb-5">
          <ErrorLoading />
        </div>
      ) : isError ? (
        <div className="pt-5">
          <ErrorLoading />
        </div>
      ) : (
        <div className="p-2 my-5 lg:px-20 flex justify-start gap-3  flex-wrap">
          {folders === null || folders.length === 0 ? (
            <p className="text-gray-600 text-center mx-auto w-fit">
              Images will come up soon. Stay Tuned
            </p>
          ) : (
            folders.map((folder, index) => (
              <button
                key={`${folder}-${index}`}
                onClick={() => {
                  setSelectedFolder(folder.name);
                  fetchImages(folder.name);
                }}
                className={`px-6 py-2 rounded-full shadow-md transition ${
                  selectedFolder === folder.name
                    ? "bg-[#00cdd2] text-white"
                    : "border text-gray-700"
                }`}
              >
                {folder.name}
              </button>
            ))
          )}
        </div>
      )}

      {imageLoading ? (
        <div className="pb-5">
          <PageLoader />
        </div>
      ) : isError ? (
        <div className="pt-5">
          <ErrorLoading />
        </div>
      ) : (
        <AnimatePresence>
          {images === null ? (
            <motion.div
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-center">Empty Folder</h2>
            </motion.div>
          ) : (
            <div>
              {/* Image Grid */}
              <motion.div
                initial={{ y: -300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-3 px-2 lg:px-20 pb-20"
              >
                {images.map((item, index) => (
                  <div
                    key={`${item}-${index}`}
                    className="cursor-pointer transform scale-95 transition-transform duration-500 ease-in-out hover:scale-105 rounded-lg shadow-lg overflow-hidden"
                    onClick={() => setSelectedIndex(index)}
                  >
                    <img
                      src={item}
                      alt={`Gallery Image ${index + 1}`}
                      className="w-full h-[16rem] rounded-lg object-cover"
                    />
                  </div>
                ))}
              </motion.div>

              {/* Fullscreen Image Viewer */}
              <AnimatePresence>
                {selectedIndex !== null && (
                  <motion.div
                    className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-[100]"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setSelectedIndex(null)}
                  >
                    <div className="relative max-w-4xl w-full">
                      {/* Close Button */}
                      <button
                        className="absolute top-4 right-4 bg-white text-black p-2 rounded-full"
                        onClick={() => setSelectedIndex(null)}
                      >
                        <X size={24} />
                      </button>

                      {/* Image Display with Swipe Gesture */}
                      <motion.img
                        key={selectedIndex} // Ensures animation resets when changing images
                        src={images[selectedIndex]}
                        alt={`Gallery Image ${selectedIndex + 1}`}
                        className="w-full max-h-[80vh] object-contain"
                        onClick={(e) => e.stopPropagation()} // Prevent closing on image click
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={0.2}
                        onDragEnd={(event, info) => {
                          if (info.offset.x > 50) handlePrev(); // Swipe right
                          if (info.offset.x < -50) handleNext(); // Swipe left
                        }}
                      />

                      {/* Navigation Controls */}
                      <button
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white text-black p-2 rounded-full"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePrev();
                        }}
                      >
                        <ArrowLeft size={24} />
                      </button>
                      <button
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white text-black p-2 rounded-full"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNext();
                        }}
                      >
                        <ArrowRight size={24} />
                      </button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default Gallery;
