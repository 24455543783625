import React, { useState, useEffect, useRef } from "react";
import backgroundImage from "../../assets/images/testimage.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { motion } from "framer-motion";
import { useGetAllEnabledTestimoniesQuery } from "../../Slices/userServiceSlice";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "./SwiperStyles.css";
import PageLoader from "../preloader/PageLoader";

const TestimonialSlide = () => {
  const { data, isLoading, isError } = useGetAllEnabledTestimoniesQuery();
    const [testimonials, setTestimonials] = useState([]);
  
    const swiperRef = useRef(null); // Reference to Swiper
  
    useEffect(() => {
      if (data) {
        setTestimonials(data.data);
      }
    }, [data]);

  return (
    <Swiper
        slidesPerView={1.2}
        centeredSlides={true}
        spaceBetween={16}
        modules={[Pagination, Navigation, Autoplay]}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{ clickable: true }}
        breakpoints={{
          640: {
            slidesPerView: 1.2,
            spaceBetween: 30,
          },
        }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        onSwiper={(swiper) => (swiperRef.current = swiper)} // Store swiper instance
        className="mySwiper w-full rounded-lg overflow-hidden"
      >
        {/* Handle Loading and Errors */}
        {isLoading ? (
          <div className="pb-5">
            <PageLoader />
          </div>
        ) : isError ? (
          <div className="pt-5">
            <PageLoader />
          </div>
        ) : testimonials.length > 0 ? (
          testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              {/* Background Section for Each Testimonial */}
              <div
                className="relative bg-cover bg-center h-[500px] md:h-[510px] flex flex-col items-center justify-center text-white w-full rounded-lg overflow-hidden"
                style={{ backgroundImage: `url(${backgroundImage})` }}
              >
                {/* Header */}
                <motion.div
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="space-y-2   px-4 text-center"
                >
                  <div className="flex items-center gap-x-2 md:gap-x-5">
                    <hr className=" w-[67.79px] h-[1px] bg-[#fff]/40 " />
                    <p className="text-[#2D2D2D] font-inter font-semibold text-[16px] sm:text-[18px]">
                      Success Stories
                    </p>{" "}
                    <hr className=" w-[67.79px] h-[1px] bg-[#fff]/40 " />
                  </div>

                  <h1 className="text-white font-bold text-[24px] sm:text-[39px]">
                    What People Say
                  </h1>
                </motion.div>

                {/* Testimonial Content */}
                <motion.div
                  initial={{ y: -300, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-full sm:w-[85%] max-w-3xl text-center space-y-4 p-4 sm:p-8"
                >
                  <div className="space-y-1">
                    <h3 className="text-[#212121] font-bold text-[16px] sm:text-[20px]">
                      {testimonial.first_name} {testimonial.last_name}
                    </h3>
                    <h3 className="text-[#0F45C3] text-[16px] sm:text-[20px]">
                      {testimonial.city} ({testimonial.location})
                    </h3>
                  </div>
                  <p className="font-inter font-semibold text-[14px] sm:text-[20px] text-[#212121]">
                    {testimonial.message}
                  </p>
                </motion.div>

                {/* Custom Navigation Buttons */}
                <button
                  onClick={() => swiperRef.current?.slidePrev()} // Move to previous slide
                  className="absolute left-4 sm:left-20 top-1/2 transform -translate-y-1/2 bg-black/60 p-2 sm:p-3 rounded-full hover:bg-black/80 transition"
                >
                  <FaChevronLeft size={20} />
                </button>
                <button
                  onClick={() => swiperRef.current?.slideNext()} // Move to next slide
                  className="absolute right-4 sm:right-20 top-1/2 transform -translate-y-1/2 bg-black/60 p-2 sm:p-3 rounded-full hover:bg-black/80 transition"
                >
                  <FaChevronRight size={20} />
                </button>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <p className="text-center text-white">No testimonials available.</p>
        )}
      </Swiper>
  )
};

export default TestimonialSlide;
