import React from "react";
import Helping from "../../assets/images/bluehand.png";
import testi from "../../assets/images/Group 3570.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import TestimonialSlide from "./testimonialSlide";

const Testimonial = () => {
  

  return (
    <div className="flex flex-col w-full justify-center items-center mt-7 space-y-12 ">
      {/* Swiper Component Wrapping the Background Section */}
      <TestimonialSlide />

      {/* Bottom Section */}
      <div className="flex flex-col lg:flex-row items-center justify-center gap-12 lg:gap-20 px-4 sm:px-10 lg:px-20">
        {/* Image Section */}
        <motion.div
          initial={{ x: -300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="relative w-full max-w-[300px] sm:max-w-[600px]"
        >
          <img src={testi} alt="Testimonial" className="w-full rounded-lg" />
        </motion.div>

        {/* Text Section */}
        <motion.div
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-left lg:max-w-lg space-y-4"
        >
          <p className="font-semibold text-lg leading-7 font-inter text-[#252A34]">
            Help put a Smile on their faces
          </p>
          <h1 className="font-medium text-2xl sm:text-4xl leading-8 sm:leading-12 text-[#252A34]">
            Together, we can create lasting change
          </h1>
          <p className="text-[#444444] font-normal text-sm sm:text-base leading-5 sm:leading-6 font-inter md:pr-10 lg:pr-0">
            Join us in bringing hope to thousands of women and children.
            Together, we can make a meaningful difference in their lives.
          </p>
          <Link to="/donate">
            <button className="mt-5 flex items-center justify-center w-[196px] h-[44px] px-6 py-2 rounded-full bg-[#0F45C3] text-white font-inter font-bold text-sm leading-[30px] transition hover:bg-[#0D3CA1]">
              Donate Now
              <img src={Helping} alt="Helping Hand" className="w-5 h-5 ml-2" />
            </button>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default Testimonial;
