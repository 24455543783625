import { apiOne, apiTwo } from "./apiSlice";

export const userServiceApiSlice = apiOne.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `userService/user/dashboard/authenticate`,
        method: "POST",
        body: data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `userService/user/dashboard/initiateForgotPassword`,
        method: "POST",
        body: data,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (data) => ({
        url: `userService/user/dashboard/completeForgotPassword`,
        method: "POST",
        body: data,
      }),
    }),
    newPassword: builder.mutation({
      query: (data) => ({
        url: `userService/user/dashboard/createNewPassword`,
        method: "POST",
        body: data,
      }),
    }),
    contactUs: builder.mutation({
      query: (data) => ({
        url: `userService/user/contactUs`,
        method: "POST",
        body: data,
      }),
    }),
    getReferralCodesforRegistration: builder.query({
      query: (data) => ({
        url: `userService/referrals/getReferralCodesForRegistration?donationAmount=${data}`,
        keepUnusedDataFor: 5,
      }),
    }),
    getReferralCodeInfo: builder.query({
      query: (data) => ({
        url: `userService/referrals/getReferralCodeInfo?referralCode=${data}`,
      }),
    }),
    getDashboardCards: builder.query({
      query: (data) => ({
        url: `userService/user/dashboard/getDashboardCards?email=${data}`,
      }),
    }),
    getAllEnabledTestimonies: builder.query({
      query: (data) => ({
        url: `userService/user/testimonials`,
      }),
    }),
    getAllFolders: builder.query({
      query: () => ({
        url: `userService/user/gallery/allFolders`,
        keepUnusedDataFor: 5,
      }),
    }),

  }),
});

export const {
  useLoginMutation,
  useNewPasswordMutation,
  useVerifyOtpMutation,
  useForgotPasswordMutation,
  useContactUsMutation,
  useGetReferralCodesforRegistrationQuery,
  useGetReferralCodeInfoQuery,
  useGetDashboardCardsQuery,
  useGetAllEnabledTestimoniesQuery,
  useGetAllFoldersQuery
} = userServiceApiSlice;

export const authUserServiceApiSlice = apiTwo.injectEndpoints({
  endpoints: (builder) => ({
    updateProfile: builder.mutation({
      query: (data) => ({
        url: ``,
        method: "POST",
        body: data,
      }),
    }),

    getPersonalInfo: builder.query({
      query: () => ({
        url: ``,
        keepUnusedDataFor: 5,
      }),
    }),
  }),
});

export const { useGetPersonalInfoQuery, useUpdateProfileMutation } =
  authUserServiceApiSlice;
